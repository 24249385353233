.introduction {
    text-align: center;
    min-height: 35vh;
    margin-bottom: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: transparent;
}

.introduction-header {
    padding: 25px;
}

.introduction-name {
    padding: 25px;
}

.introduction-list {
    font-size: 150%;
}