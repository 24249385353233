.about-tech {
    padding-top: 5vh;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vh;
}

.about-tech h1 {
    font-size: calc(48px + 2vmin);
    padding-bottom: 10vh;
}

.about-tech-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.icon-box {
    flex: 0 0 calc(25% - 30px);
    margin: 10px;
}

.icon {
    width: fit-content;
    margin-left: 30%;
}