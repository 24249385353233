.projects-examples {
    display: flex;
    margin-top: 13vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25vh;
    flex-wrap: wrap;
    justify-content: center;
}

.projects-examples-box {
    flex-wrap: wrap;
    justify-content: center;
    scale: 125%;
    flex: 0 0 calc(35% - 20px);
    padding: 10px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(150deg, #181818B2 25%, #0C3C01CC 100%);
    backdrop-filter: blur(5px);
    border-radius: 15px;
}

.projects-examples-box h2 {
    font-size: calc(9px + 2vmin);
}
.projects-examples-box p {
    font-size: calc(1px + 2vmin);
    text-align: left;
    padding: 10px;
}

.projects-examples-box a {
    text-decoration: none;
    color: white;
}

.projects-button {
    display: flex;
    margin: auto;
    scale: 80%;
    width: fit-content;
    border-radius: 5px;
    padding: 5px;
    text-decoration: none;
    background: #181818;
}

.projects-button img {
    width: 20px;
    height: 20px;
    margin: 5px;
}
