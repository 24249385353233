.backgroundVideo {
    /* relative to parrent "App"*/
    width: 100vw;
    height: 100vh;
    background-color: #181818;
    position: fixed;
    top: 0;
    left: 0;
    filter: brightness(90%);
    z-index: -100;
    overflow: hidden;
}

.videoBg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}