.Who-I-Am {
    display: grid;
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vh;
    background: linear-gradient(150deg, #181818B2 25%, #0C3C01CC 100%);
    backdrop-filter: blur(5px);
    border-radius: 15px;
}

.who-I-Am-text {
    grid-column: 1;
}

.who-I-Am-img {
    grid-column: 2;
    align-self: center;
    padding: 2vmax;
    width: 30vw;
    height: auto;
    border-radius: 50%;
}

.title {
    padding: 15px;
    font-size: calc(40px + 2vmin);
}

.description p{
    padding: 15px;
    justify-content: space-between;
    font-size: calc(4px + 2vmin);
}

.headshot {
    width: 400px;
    height: 400px;
    grid-column: 2;

}