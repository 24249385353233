.navbar {
    position: fixed;
    max-height: 20vmax;
    top: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.menu {
    align-items: center;
    padding-right: 15px;
    width: 50vw;
}

.menu-items{
    display: flex;
    gap: 3vw;
    list-style: none;
    margin: 10px;
    width: 100%;
}
.item {
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
    height: auto;
    max-width: 15vmax;
    max-height: 10vmax;
}

.item img {
    width: 2vw;
    height: auto;
    margin-right: 5px;
    margin-bottom: -4%;
}

.item a{
    font-size: 1.9vw;
    text-decoration: none;
    font-weight: bold;
    color: white;
}

.my-logo-img{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 50px;
    width: 10vmax;
    height: 10vmax;
 }