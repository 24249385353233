.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: rgba(12, 60, 1, 0.8);
    backdrop-filter: blur(10px);
    color: white;
}

.footer-left {
    display: flex;
    align-items: center;
}

.footer-center {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.footer-img {
    display: flex;
    width: 35px;
    height: 35px;
}

.footer-right {
    display: flex;
    align-items: center;
}